import axios from 'axios';

export const fetchSubmissions = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/getSubmissions`, {});
        return response.data.submissions
    } catch (e) {
        return false
    }
}

export const fetchSubmission = async (submissionID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/getSubmission`, {submissionID: submissionID});
        return response.data
    } catch (e) {
        return false
    }
}

export const approveSubmission = async (data, jonasVendorCode, companyCode, purchaseCategory) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/approveSubmission`, {data: data, jonasVendorCode: jonasVendorCode, companyCode: companyCode, purchaseCategory: purchaseCategory});
        return response.data
    } catch (e) {
        return false
    }
}

export const deleteSubmission = async (submissionId) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/deleteSubmission`, {submissionId});
        return response.data
    } catch (e) {
        return false
    }
}

export const requestSubmissionUpdate = async (submissionID, reason) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/requestSubmissionUpdate`, {submissionID: submissionID, reason: reason})
        return response.data
    } catch (e) {
        return false
    }
}

export const fetchUsers = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/fetchUsers`, {})
        return response.data
    } catch {
        return false
    }
}

export const addNewUser = async (firstName, lastName, email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/addNewUser`, {firstName, lastName, email})
        return response.data
    } catch {
        return false
    }
}

export const removeUser = async (userID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/removeUser`, {userID})
        return response.data
    } catch {
        return false
    }
}
