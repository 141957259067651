import axios from 'axios'

export const getSubmissionID = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submission`, {});
        return response.data;
    } catch(e) {
        return false
    }
}

export const updateSubmission = async (submissionId, data, token) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/submission/${submissionId}`, {data, token});
        return response.data;
    } catch(e) {
        return false
    }
}

export const verifyUpdateRequestID = async (submissionID, updateID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/submission/verifyUpdateID`, {submissionID, updateID});
        return response.data;
    } catch (e) {
        return false
    }
}

export const getABNDetails = async (abn) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/submission/abn/${abn}`);
        return response.data;
    } catch(e) {
        return false
    }
}

export const getACNDetails = async (acn) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/submission/acn/${acn}`);
        return response.data;
    } catch(e) {
        return false
    }
}