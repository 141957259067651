import React from "react";
import { ReactComponent as Logo } from '../images/logo.svg'
import './Login.scss';

export default function Login() {

    return(
        <div className="LoginContainer">
            <Logo className="icmlogo" />
            <h2>Log In</h2>
            <p>Please sign in with Microsoft below. </p>
            <p>You will be redirected back once you have successfully logged in.</p>
            <a href={`${process.env.REACT_APP_API_URL}/api/authentication/microsoft`}>Sign in with Microsoft</a>
        </div>
    )

}
