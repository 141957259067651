import React, {Component} from "react";
import './Admin.scss'
import { fetchSubmissions, deleteSubmission } from '../api/adminAPI'
import { Table, Space, Tag, Input, Modal} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submissions: [],
            searchValue: "",
            isDeletePopupOpen: false,
            deleteid: "",
        }
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.deleteSubmission = this.deleteSubmission.bind(this);
        this.toggleDeletePopup = this.toggleDeletePopup.bind(this);

    }

    componentDidMount() {
        fetchSubmissions()
        .then(data => {
            this.setState({ ...this.state, submissions: data || []})
        }).catch((err) => console.log(err))
    }

    changeSearchValue(e) {
        this.setState({...this.state, searchValue: e.target.value});
    }

    deleteSubmission(id) {
        deleteSubmission(id)
        .then(data => {
            if(data.success) {
                this.setState({...this.state, submissions: this.state.submissions.filter(submission => submission.id !== id), deleteid: '', isDeletePopupOpen: false})
            }
        }).catch((err) => console.log(err))
    }

    toggleDeletePopup() {
        this.setState({ ...this.state, isDeletePopupOpen: !this.state.isDeletePopupOpen, deleteid: ''})
    }

    render() {
        // Filter our original array with the submission data and use this as our data source for the table as it filters by Company Name.
        // We are not changing the original array so we can use it to search within the array.
        const filteredData = this.state.submissions.filter(entry => {
            return entry.companyName.toLowerCase().includes(this.state.searchValue.toLowerCase())
        });

        const columns = [
            {
                title: 'Submission ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => (
                    <Tag color="green">
                        {text}
                    </Tag>
                ),
                filters: [
                    {text: 'Pending ICM Approval', value: 'PENDING_ICM_APPROVAL'},
                    {text: 'Awaiting Correction', value: 'AWAITING_CORRECTION'},
                    {text: 'Approved', value: 'APPROVED'},
                    {text: 'Rejected', value: 'REJECTED'},
                    
                ],
                onFilter: (value, record) => {
                    return record.status === value;
                },
            },
            {
                title: 'Company Name',
                dataIndex: 'companyName',
                key: 'companyName',
            },
            {
                title: 'Last Updated Date & Time',
                key: 'tags',
                dataIndex: 'updatedAt',
                render: (text) => (
                    <Space size="middle">
                        {new Date(text).toLocaleDateString(
                            'en-gb',
                        )}
                        {new Date(text).toLocaleTimeString()}
                    </Space>
                ),
                sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
                defaultSortOrder: 'descend',
            },
            {
                title: 'Actions',
                key: 'action',
                render: (text, record) => (
                    <div>
                        <Space size="small">
                            <Link className="viewbutton" to={`/admin/submission/${record.id}`}>View Submission</Link>
                            {record.status === "PENDING_ICM_APPROVAL" ? <button className="deleteButton" onClick={() => this.setState({ ...this.state, isDeletePopupOpen: !this.state.isDeletePopupOpen, deleteid: record.id})}>Delete Submission</button> : null}
                        </Space>
                    </div>
                
                ),
                width: "15%"
            },
        ];

        return (
            <div className="submissiontable">
                <Modal title="Delete Submission" visible={this.state.isDeletePopupOpen} onOk={() => this.deleteSubmission(this.state.deleteid)} onCancel={this.toggleDeletePopup}>
                    <h3 style={{color:"red"}}>Are you sure you want to delete this submission?</h3>
                </Modal>
                <div className="adminsearchinput">
                    <Input prefix={<FontAwesomeIcon className="adminsvg" icon={faSearch}/>} placeholder="Search for Submission.." name="searchValue" onChange={this.changeSearchValue}/>
                </div>
                <Table columns={columns} dataSource={filteredData} pagination={{defaultPageSize: 15, showSizeChanger: true, pageSizeOptions: ['10', '15', '30']}}/>
            </div>
        )
    }

}

export default Admin;
