import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

//Component Imports for use with routing.
import Form from './pages/Form';
import Home from './pages/Home';
import Success from './pages/Success'
import NotFound from './pages/404';
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Submission from "./pages/Submission";
import Navbar from "./components/Navbar";
import Users from "./pages/Users";
import { history } from "./helpers/history";
import "./App.css";
import 'antd/dist/antd.css';
import React from "react";
import AuthCheck from "./components/AuthCheck";

class App extends React.Component {

  render() {
    return (
      <Router history={history}>
          <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/form/:formID" component={Form} />
              <Route exact path="/form/:formID/success" component={Success} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/admin">
                <AuthCheck>
                  <Navbar title="All Submissions">
                    <Admin />
                  </Navbar>
                </AuthCheck>
              </Route>
              <Route exact path="/admin/submission/:submissionID">
                <AuthCheck>
                  <Navbar title="View Submission">
                    <Submission />
                  </Navbar>
                </AuthCheck>
              </Route>
              <Route exact path="/users">
                <AuthCheck>
                  <Navbar title="Manage Users">
                    <Users />
                  </Navbar>
                </AuthCheck>
              </Route>
              <Route component={NotFound} />
          </Switch>
      </Router>
    );
  }
}

export default App;
