import axios from 'axios'

export const handleFileUpload = async (formData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/files/handleFileUpload`, formData);
        return response.data;
    } catch(e) {
        return false
    }
}

export const fetchSubmissionFiles = async (submissionID, fileID) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/files/fetchSubmissionFiles`, {submissionID: submissionID, fileID: fileID}, {responseType: 'blob'});
        return response.data;
    } catch(e) {
        return false
    }
}

