import './Form.scss';
import React from 'react'
import { withRouter } from 'react-router-dom';
import { ReactComponent as Logo } from '../images/logo.svg'
import { updateSubmission, verifyUpdateRequestID } from '../api/submissionAPI';

import StageOne from '../pages/stages/StageOne'
import StageTwo from '../pages/stages/StageTwo'
import StageThree from '../pages/stages/StageThree'
import StageFour from '../pages/stages/StageFour'
import StageFive from '../pages/stages/StageFive'
import StageSix from './stages/StageSix'
import StageSeven from './stages/StageSeven'
import StageEight from './stages/StageEight'
import StageNine from './stages/StageNine'
import StageTen from './stages/StageTen'
import StageEleven from './stages/StageEleven'
import StageTwelve from './stages/StageTwelve'
import StageThirteen from './stages/StageThirteen';


class SubmissionForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                tradingName: "",
                abn: "",
                abnIsTrust: false,
                acn: "",
                supplierAddress: "",
                supplierTownSuburb: "",
                supplierPostcode: "",
                supplierState: "",
                accountContactName: "",
                accountContactPhone: "",
                accountContactEmail: "",
                useAccountEmailForRemittance: false,
                remittanceEmail: "",
                purchaseOrderEmail: "",
                useSupplierAddressForAccount: false,
                accountAddress: "",
                accountTownSuburb: "",
                accountPostcode: "",
                accountState: "",
                bankName: "",
                bankBSB: "",
                bankAccountName: "",
                bankAccountNumber: "",
                creditorType: [],
            },
            stage: 1,
            isLoading: true,
            submissionID: this?.props?.match?.params?.formID,
            updateID: new URLSearchParams(this?.props?.location?.search).get("update"),
            fileData: [],
        }

        this.handleNextStage = this.handleNextStage.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleFileData = this.handleFileData.bind(this)
    }

    componentDidMount() {
        if (this.state.updateID) {
            verifyUpdateRequestID(this.state.submissionID, this.state.updateID)
            .then(data => {
            if (data.success === true) {
                this.setState({...this.state, data: data.data, isLoading: false})
            } else if (data.invalid === true) {
                this.props.history.push('/')
            }
            }).catch((err) => console.log(err))
        } else {
            this.setState({...this.state, isLoading: false})
        }     
    }

    /* Function provided as a prop to each "Step" component to update the state data and change the stage.
       Checks if we are at the last "Stage" by comparing the current stage to the last index of the stages array.
       If we are call our recaptcha to execute as we only want this to happen when the user submits the form as the
       token expires in 2m. Call our function to handle submitting the data and pass the token in for verification.*/ 
    handleNextStage = (data) => {
        this.setState({...this.state, data: data});
        if (this.state.stage === 13) {
            window.grecaptcha.ready(() => { //6Le3XSMcAAAAABV3VVjAIkBV29NtefPlRnWK3Qyg // 6LfdPBscAAAAAJJ5loutbnrVEFequPdBNaWvgICD (DEV)
                window.grecaptcha.execute(`6Le3XSMcAAAAABV3VVjAIkBV29NtefPlRnWK3Qyg`, { action: 'submit' }).then(token => {
                    this.handleSubmit(this.state.data, token)
                });
            });
        } else {
            this.setState({...this.state, stage: this.state.stage + 1});
        }  
    }

    // handleSubmit will handle the API calls with the data to our backend.
    handleSubmit = async (data, token) => {
        const submission = await updateSubmission(this.state.submissionID, data, token)

        // Checking the response object to see if the submission was a success, and if it was push to success page.
        if (submission.err === false) {
            this.props.history.push(`/form/${this.state.submissionID}/success`)
        }
    }

    handleFileData = (data) => {
        if (data.length === 0) {
            return
        }
        this.setState(prevState => ({
            fileData: prevState.fileData.push(data)
        }))
    }

    render() {
        if (this.state.isLoading) return (<div></div>)
        return (
            <div className="Form">
                <Logo className="icmlogo"/>
                {this.state.stage === 1 &&
                    <StageOne handleNext={this.handleNextStage} formData={this.state.data} />
                }

                {this.state.stage === 2 &&
                    <StageTwo handleNext={this.handleNextStage} formData={this.state.data} />
                }

                {this.state.stage === 3 &&
                    <StageThree handleNext={this.handleNextStage} formData={this.state.data} />
                }

                {this.state.stage === 4 &&
                    <StageFour handleNext={this.handleNextStage} formData={this.state.data} />
                }

                {this.state.stage === 5 &&
                    <StageFive handleNext={this.handleNextStage} formData={this.state.data} />
                }

                {this.state.stage === 6 &&
                    <StageSix handleNext={this.handleNextStage} formData={this.state.data}/>
                }

                {this.state.stage === 7 &&
                    <StageSeven handleNext={this.handleNextStage} formData={this.state.data} handleFileData={this.handleFileData} creditorType={this.state.creditorType}/>
                }

                {this.state.stage === 8 &&
                    <StageEight handleNext={this.handleNextStage} formData={this.state.data} handleFileData={this.handleFileData} creditorType={this.state.creditorType}/>
                }

                {this.state.stage === 9 &&
                    <StageNine handleNext={this.handleNextStage} formData={this.state.data} handleFileData={this.handleFileData} creditorType={this.state.creditorType}/>
                }

                {this.state.stage === 10 &&
                    <StageTen handleNext={this.handleNextStage} formData={this.state.data} handleFileData={this.handleFileData} creditorType={this.state.creditorType}/>
                }

                {this.state.stage === 11 &&
                    <StageEleven handleNext={this.handleNextStage} formData={this.state.data} handleFileData={this.handleFileData} creditorType={this.state.creditorType}/>
                }

                {this.state.stage === 12 &&
                    <StageTwelve handleNext={this.handleNextStage} formData={this.state.data} handleFileData={this.handleFileData} creditorType={this.state.creditorType}/>
                }

                {this.state.stage === 13 &&
                    <StageThirteen handleNext={this.handleNextStage} formData={this.state.data} handleFileData={this.handleFileData} creditorType={this.state.creditorType}/>
                }
            </div>
        )
    }
}

export default withRouter(SubmissionForm);
