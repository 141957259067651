import React, {Component} from "react";
import './Users.scss'
import { Table, Space, Input, Modal, Popconfirm, Button } from 'antd';
import { fetchUsers, addNewUser, removeUser } from "../api/adminAPI";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            isModalOpen: false,
            userFirstName: '',
            userLastName: '',
            userEmail: '',
            modalErr: '',
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOk = this.handleOk.bind(this);
    }

    componentDidMount() {
        this.getUsers()
    }

    getUsers() {
        fetchUsers()
        .then(data => {
            this.setState({...this.state, users: data.users})
        }).catch((err) => console.log(err));
    }

    deleteUser(id) {
        removeUser(id)
        .then(
            this.getUsers()
        ).catch((err) => console.log(err))
    }

    toggleModal() {
        this.setState({ ...this.state, isModalOpen: !this.state.isModalOpen, userFirstName: '', userLastName: '', userEmail: '', modalErr: ''})
    }

    handleChange(e) {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

    handleOk() {

        if (this.state.userFirstName === "") {
            this.setState({...this.state, modalErr: "Please enter a valid first name."})
        } 
        
         else if (this.state.userLastName === '') {
            this.setState({...this.state, modalErr: "Please enter a valid last name."})
        } 

        else if (this.state.userEmail === '') {
            this.setState({...this.state, modalErr: "Please enter a valid email address."})
        }

         else if (!this.state.modalErr) {
            this.toggleModal()

            addNewUser(this.state.userFirstName, this.state.userLastName, this.state.userEmail)
            .then(
                this.getUsers()
            ).catch((err) => console.log(err));
        }
    }

    render() {
        const columns = [
            {
                title: 'First Name',
                dataIndex: 'firstname',
                key: 'firstname',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastname',
                key: 'lastname',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Actions',
                key: 'action',
                render: (text, record) => (
                <Space size="middle">
                 <Popconfirm
                      title="Are you sure to delete this user?"
                      onConfirm={() => this.deleteUser(record.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="deluser">Delete User</button>
                    </Popconfirm>
                </Space>
                ),
            },
        ];
        return(
            <div className="users">
                <Button className="newuserbutton" type="primary" onClick={this.toggleModal}>Add User</Button>
                <Modal title="Add User" visible={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.toggleModal}>
                    <div className="modalinput">
                        <h3>First Name</h3>
                        <Input placeholder="John" value={this.state.userFirstName} onChange={this.handleChange} name="userFirstName" />
                    </div>
                    
                    <div className="modalinput">
                        <h3>Last Name</h3>
                        <Input placeholder="Smith" value={this.state.userLastName} onChange={this.handleChange} name="userLastName" />
                    </div>
                    
                    <div className="modalinput">
                        <h3>Email Address</h3>
                        <Input placeholder="user@icmco.com.au" value={this.state.userEmail} onChange={this.handleChange} name="userEmail" />
                    </div>

                    <p className="modalerror">{this.state.modalErr}</p>

                </Modal>
                <div className="usertable">
                    <Table dataSource={this.state.users} columns={columns}  pagination={{defaultPageSize: 30}}/>
                </div>
            </div>
            
        )
    }
}

export default Users;
