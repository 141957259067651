import React, {Component} from "react";
import { withRouter } from "react-router-dom";
import './Home.scss';
import { ReactComponent as Logo } from '../images/logo.svg'
import { getSubmissionID } from "../api/submissionAPI";

class Home extends Component {
    
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = async () => {
        const data = await getSubmissionID({});
        
        this.props.history.push(`/form/${data.submissionId}`);
    }

    render() {
        
        return <div className="Home">
            <Logo className="icmlogo"/>

            <div className="homescreen">
                <h2 className="homeheader">Creditor Portal</h2>
                <p className="hometext">You can use this portal to submit all of your company and licence information.</p>
                <button className="homebutton" type="button" onClick={this.handleClick}>Get Started</button>
            </div>
        </div>
    }
}

export default withRouter(Home);
